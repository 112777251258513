import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

const useGetInstances = () => {
  const {fetchChatInfo} = useRequest();

  const getInstances = async (user: AppEnv.User) => {
    const newInstances: AppEnv.Instances = {};

    const promises = Object.entries(user.instances).map(
      async ([id, instance]) => {
        const chatInfo = await fetchChatInfo(instance.chat_key);
        if (!chatInfo) return;

        // dashboard returns instances without ids
        newInstances[+id] = {...chatInfo, ...instance, id: +id};
      }
    );

    await Promise.all(promises);
    return newInstances;
  };

  return getInstances;
};

export default useGetInstances;
