import React from 'react';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {Seq, Text} from 'components';
import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

interface Blacklisted {
  blacklisted: AppEnv.Blacklist;
}

const Blacklisted = ({blacklisted}: Blacklisted) => {
  const {setNotification} = useAppContext();
  const {setUser, user} = useWorkspaceContext();

  const {fetchBlacklistDelete} = useRequest();
  const {t} = useTranslation();

  const handleClick = async () => {
    const res = await fetchBlacklistDelete(
      blacklisted.integration_id,
      blacklisted.id
    );

    if (!res?.success) return true;

    setNotification({title: t`Chat unblocked`});

    if (!('blacklist' in user)) return true;

    const index = user.blacklist.findIndex(item => item.id == blacklisted.id);
    if (index < 0) return true;

    setUser(prevValue => upd(prevValue, {blacklist: {$splice: [[index, 1]]}}));
    return true;
  };

  return (
    <Seq
      confirm={t`Unblock this chat?`}
      key={blacklisted.id}
      onClick={handleClick}
    >
      <Text fontWeight={600}>{blacklisted.phone}</Text>
    </Seq>
  );
};

export default Blacklisted;
