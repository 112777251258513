import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {useInstancesContext} from 'pages/workspace/components/instances/instances-context';
import {Button, Confirm, Icons} from 'components';
import {getIsInstanceWorking} from 'common/actions';
import {useChatSpare} from 'pages/workspace/components/instances/hooks';
import {useInstanceContext} from '../../instance-context';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

interface Connect {
  isFetching: boolean;
  setIsFetching: AppEnv.SetState<boolean>;
}

const Connect = ({isFetching, setIsFetching}: Connect) => {
  const {setConnectorId} = useWorkspaceContext();
  const {onDelete} = useInstancesContext();
  const {instance} = useInstanceContext();

  const [isConfirmActive, setIsConfirmActive] = useState(false);

  const {fetchChatFree} = useRequest();
  const {t} = useTranslation();
  const chatSpare = useChatSpare();

  const updateConnectorId = (instance: AppEnv.Instance) => {
    setConnectorId(instance.id);
    setIsFetching(false);
  };

  const handleClick = () => {
    const isWorking = getIsInstanceWorking(instance);
    if (!isWorking) return updateConnectorId(instance);

    setIsConfirmActive(true);
  };

  const updateIsFetching = () => {
    setIsFetching(false);
    return true;
  };

  const recreateInstance = async () => {
    setIsFetching(true);

    const free = await fetchChatFree(instance.chat_key);
    if (!free) return updateIsFetching();

    onDelete(instance.id);

    const spare = await chatSpare(instance.version, {
      label: instance.label,
      name: instance.version == 'chat' ? instance.name : undefined
    });

    if (!spare) return updateIsFetching();

    setConnectorId(spare.id);
    setIsFetching(false);

    return true;
  };

  const handleConfirm = (res: boolean) =>
    res ? recreateInstance() : setIsConfirmActive(false);

  const isWorking = getIsInstanceWorking(instance);

  return (
    <>
      <Button color="transparent" isDisabled={isFetching} onClick={handleClick}>
        <Icons.QrCode size={20} />
        <span>{isWorking ? t`Reconnect` : t`Connect`}</span>
      </Button>

      <Confirm
        title={t`Are you sure you want to change the phone number?`}
        isActive={isConfirmActive}
        onClick={handleConfirm}
      />
    </>
  );
};

export default Connect;
