import {getIsDialogGroup} from 'common/actions';
import * as AppEnv from 'app-env';

const useGetFolderDialogList = () => {
  const getFolderDialogList = (
    dialogList: AppEnv.Dialog[],
    folder: AppEnv.Folder
  ) => {
    const getIsArchived = (dialog: AppEnv.Dialog) =>
      dialog.version == 'whatcrm' ? dialog.archived : false;

    return dialogList.filter(item => {
      const isArchived = getIsArchived(item);
      const isGroup = getIsDialogGroup(item);

      if (folder == 'archive') {
        return isArchived;
      } else if (folder == 'direct') {
        return !isArchived && !isGroup;
      } else if (folder == 'groups') {
        return !isArchived && isGroup;
      }

      return !isArchived;
    });
  };

  return getFolderDialogList;
};

export default useGetFolderDialogList;
