import React from 'react';
import {Form, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import upd from 'immutability-helper';

import {
  Button,
  Popup,
  PopupFooter,
  PopupWrapper,
  SaveButton,
  Title,
  Wrapper
} from 'components';
import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import Phone from './phone/phone';

interface Block {
  isBlockActive: boolean;
  setIsBlockActive: AppEnv.SetState<boolean>;
}

interface Values {
  phone: string;
}

const validationSchema = yup
  .object()
  .shape({phone: yup.string().trim().required()});

const Block = ({isBlockActive, setIsBlockActive}: Block) => {
  const {setNotification} = useAppContext();
  const {setUser, user} = useWorkspaceContext();

  const {postBlacklistCreate} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: Values) => {
    const {integration} = user;

    const res = await postBlacklistCreate(integration.id, values.phone);
    if (!res) return;

    setUser(prevValue => upd(prevValue, {blacklist: {$push: [res]}}));
    setIsBlockActive(false);
    setNotification({title: t`Chat blocked`});

    return true;
  };

  return (
    <Popup
      isActive={isBlockActive}
      onClick={() => setIsBlockActive(false)}
      width={408}
    >
      <Formik
        initialValues={{phone: ''}}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        <Form>
          <PopupWrapper>
            <Title.H3 style={{marginBottom: 16}}>{t`Block chat`}</Title.H3>
            <Phone />
          </PopupWrapper>

          <PopupFooter>
            <Wrapper gap={12}>
              <SaveButton>{t`Block`}</SaveButton>

              <Button color="fade" onClick={() => setIsBlockActive(false)}>
                {t`Close`}
              </Button>
            </Wrapper>
          </PopupFooter>
        </Form>
      </Formik>
    </Popup>
  );
};

export default Block;
