import {getIsDialogGroup, getIsLimited} from 'common/actions';
import {useAppContext} from 'app-context';
import * as AppEnv from 'app-env';

const useGetIsDialogLimited = () => {
  const {isIframe} = useAppContext();

  const getIsDialogLimited = (
    instance: AppEnv.Instance,
    dialog: AppEnv.Dialog
  ) => {
    if (isIframe) return false;

    const isGroup = getIsDialogGroup(dialog);
    if (!isGroup) return false;

    const isLimited = getIsLimited(instance);
    return isLimited;
  };

  return getIsDialogLimited;
};

export default useGetIsDialogLimited;
