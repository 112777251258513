import React from 'react';
import {useTranslation} from 'react-i18next';

import {Advice, Link, Text} from 'components';

const StripeAdvice = () => {
  const {t} = useTranslation();

  return (
    <Advice>
      <Text size={16}>
        {t`You can cancel your subscription on the`}{' '}
        <Link href="" isTargetBlank>{t`Stripe website`}</Link>
        {'. '}
        {t`Enter your email and confirmation code.`}{' '}
        {t`Then find our product and click “Cancel”.`}
      </Text>
    </Advice>
  );
};

export default StripeAdvice;
