import React, {useState} from 'react';
import cn from 'classnames';

import {Button, Confirm, Icons} from 'components';

interface Seq {
  children: React.ReactNode;
  confirm: string;
  onClick: () => Promise<boolean>;
}

const Seq = ({children, confirm, onClick}: Seq) => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = (res: boolean) => {
    if (res) return onClick();
    setIsActive(false);
  };

  return (
    <div className={cn('seq', {seq_active: isActive})}>
      <div>{children}</div>

      <div className="seq__btn-group">
        <Button color="transparent" onClick={() => setIsActive(true)}>
          <Icons.TrashSimple size={20} />
        </Button>
      </div>

      <Confirm isActive={isActive} onClick={handleClick} title={confirm} />
    </div>
  );
};

export default Seq;
