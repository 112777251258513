import {useTranslation} from 'react-i18next';

const useTariffPeriod = (quantity: 30 | 3 | 6 | 12) => {
  const {t} = useTranslation();

  if (quantity == 3) return t`3 months`;
  else if (quantity == 6) return t`6 months`;
  else if (quantity == 12) return t`12 months`;

  return t`30 days`;
};

export default useTariffPeriod;
