import React from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Body, Nav, Page} from 'components';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

import Partner from './partner/partner';
import SettingsMain from './settings-main/settings-main';

const Settings = () => {
  const {user} = useWorkspaceContext();

  const {t} = useTranslation();
  const location = useLocation();

  const {integration} = user;
  const options = [{label: t`Settings`, value: '/settings'}];

  if (!integration.is_partner && process.env.REACT_APP_ENV == 'dev')
    options.push({label: t`Partner`, value: '/settings/partner'});

  return (
    <Body>
      <Page>
        <Nav options={options} value={location.pathname} />

        <Routes>
          <Route path="/" element={<SettingsMain />} />
          <Route path="/partner" element={<Partner />} />
        </Routes>
      </Page>
    </Body>
  );
};

export default Settings;
