import {useTranslation} from 'react-i18next';
import * as AppEnv from 'app-env';

const useMessenger = (crm: AppEnv.Crm) => {
  const {t} = useTranslation();

  if (['AMO', 'B24', 'MONDAY', 'PIPEDRIVE'].includes(crm)) return 'WhatsApp';
  else if (['AVITO'].includes(crm)) return t`Avito`;
  else if (['TELEGRAM', 'TELPHGRAM', 'TGMONDAY'].includes(crm))
    return 'Telegram';
};

export default useMessenger;
