import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Icons, Select, SelectOption} from 'components';
import * as AppEnv from 'app-env';

const Crm = () => {
  const [field, meta, helpers] = useField<AppEnv.Crm | undefined>('crm');
  const {i18n, t} = useTranslation();

  const isError = meta.error && meta.touched;

  const kommoIcon =
    i18n.resolvedLanguage == 'ru' ? (
      <Icons.AmoCRM size={16} />
    ) : (
      <Icons.Kommo size={16} />
    );

  const options: SelectOption<AppEnv.Crm>[] = [
    {
      comment: t`Bitrix24`,
      icon: <Icons.Bitrix24 size={16} />,
      label: 'Telegram',
      value: 'TELPHGRAM'
    },
    {
      comment: t`Bitrix24`,
      icon: <Icons.Bitrix24 size={16} />,
      label: 'WhatsApp',
      value: 'B24'
    },
    {comment: t`Kommo`, icon: kommoIcon, label: t`Avito`, value: 'AVITO'},
    {comment: t`Kommo`, icon: kommoIcon, label: 'Telegram', value: 'TELEGRAM'},
    {comment: t`Kommo`, icon: kommoIcon, label: 'WhatsApp', value: 'AMO'},
    {
      comment: 'monday',
      icon: <Icons.Monday size={16} />,
      label: 'Telegram',
      value: 'TGMONDAY'
    },
    {
      comment: 'monday',
      icon: <Icons.Monday size={16} />,
      label: 'WhatsApp',
      value: 'MONDAY'
    },
    {
      comment: 'Pipedrive',
      icon: <Icons.Pipedrive size={16} />,
      label: 'Pipedrive',
      value: 'PIPEDRIVE'
    },
    {
      comment: 'Whatcrm',
      icon: <Icons.Whatcrm size={16} />,
      label: 'Whatcrm',
      value: 'LK'
    }
  ];

  return (
    <Select
      {...field}
      isValid={!isError}
      onChange={helpers.setValue}
      options={options}
      placeholder="CRM"
      style={{marginBottom: 16}}
    />
  );
};

export default Crm;
