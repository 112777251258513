import React, {useEffect} from 'react';

import {PageBody, PageMain, Preloader} from 'components';
import * as AppEnv from 'app-env';

import {Integration} from './instances-env';
import InstanceCreator from './instance-creator/instance-creator';
import InstanceList from './instance-list/instance-list';
import InstancesContext from './instances-context';

interface Instances {
  integration: Integration;
  instances: AppEnv.Instances | undefined;
  isPartner?: boolean;
  onChange: (instance: AppEnv.Instance) => void;
  onDelete: (id: number) => void;
  onMount?: () => void;
}

const Instances = ({
  instances,
  integration,
  isPartner = false,
  onChange,
  onDelete,
  onMount = () => {}
}: Instances) => {
  useEffect(() => {
    onMount();
  }, []);

  return (
    <InstancesContext.Provider
      value={{integration, isPartner, onChange, onDelete}}
    >
      <PageBody>
        <PageMain>
          {instances ? (
            <>
              <InstanceCreator />
              <InstanceList instances={instances} />
            </>
          ) : (
            <Preloader />
          )}
        </PageMain>
      </PageBody>
    </InstancesContext.Provider>
  );
};

export default Instances;
