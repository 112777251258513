import React from 'react';
import ReactCollapsible from 'react-collapsible';

import {useAccordionContext} from '../accordion-context';

interface Accordion {
  children: React.ReactNode;
}

const AccordionDetails = ({children}: Accordion) => {
  const {isActive} = useAccordionContext();

  return (
    <ReactCollapsible
      easing="ease-in-out"
      open={isActive}
      transitionTime={150}
      trigger={<></>}
    >
      <div className="accordion__details">{children}</div>
    </ReactCollapsible>
  );
};

export default AccordionDetails;
