import React from 'react';

interface TableRow {
  children: React.ReactNode;
}

const TableRow = ({children}: TableRow) => (
  <tr className="table__row">{children}</tr>
);

export default TableRow;
