import React from 'react';
import {useTranslation} from 'react-i18next';

import {Advice, Link, Text} from 'components';

const PartnerProgramAdvice = () => {
  const {t} = useTranslation();

  return (
    <Advice style={{marginBottom: 16}}>
      <Text size={16}>
        <Link href="https://wa.me/79993335628" isTargetBlank>
          {t`Write to the Partnership Department`}
        </Link>
      </Text>
    </Advice>
  );
};

export default PartnerProgramAdvice;
