import React from 'react';

interface TableHead {
  children: React.ReactNode;
}

const TableHead = ({children}: TableHead) => (
  <thead className="table__head">
    <tr className="table__row">{children}</tr>
  </thead>
);

export default TableHead;
