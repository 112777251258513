import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {Instances} from 'pages/workspace/components';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

const ConnectionsMain = () => {
  const {setUser, user} = useWorkspaceContext();
  const {t} = useTranslation();

  useEffect(() => {
    document.title = `${t`Connections`} - Whatcrm Web`;
  }, []);

  const handleChange = (instance: AppEnv.Instance) =>
    setUser(prevValue =>
      upd(prevValue, {instances: {[instance.id]: {$set: instance}}})
    );

  const handleDelete = (id: number) =>
    setUser(prevValue => upd(prevValue, {instances: {$unset: [id]}}));

  return (
    <Instances
      integration={user.integration}
      instances={user.instances}
      onChange={handleChange}
      onDelete={handleDelete}
    />
  );
};

export default ConnectionsMain;
