import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';
import {normalizeUrl} from 'whatcrm-core';

import {Input} from 'components';

const Webhook = () => {
  const [field, , helpers] = useField<string>('webhook');
  const {t} = useTranslation();

  return (
    <Input
      {...field}
      onChange={value => helpers.setValue(normalizeUrl(value))}
      placeholder={t`Webhook address`}
      type="url"
    />
  );
};

export default Webhook;
