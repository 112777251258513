import React, {useState} from 'react';
import {useDispatchContext} from '../dispatch-context';

import DialogList from './dialog-list/dialog-list';
import Folders from './folders/folders';
import Header from './header/header';
import NewChat from './new-chat/new-chat';

import {useIsNewChatShown} from './hooks';

const Dialogs = () => {
  const {instance} = useDispatchContext();

  const [search, setSearch] = useState('');
  const [isUnreadFilter, setIsUnreadFilter] = useState(false);

  const isFoldersShown = ['telegram', 'whatcrm'].includes(instance.version);
  const isNewChatShown = useIsNewChatShown();

  return (
    <div className="dialogs">
      <Header
        search={search}
        isUnreadFilter={isUnreadFilter}
        setSearch={setSearch}
        setIsUnreadFilter={setIsUnreadFilter}
      />

      {isFoldersShown && <Folders />}
      <DialogList search={search} isUnreadFilter={isUnreadFilter} />
      {isNewChatShown && <NewChat />}
    </div>
  );
};

export default Dialogs;
