import {useEffect} from 'react';

import {getIsDialogBlacklisted} from 'common/actions';
import * as AppEnv from 'app-env';

const useMarkBlacklisted = (
  user: AppEnv.User,
  dialogs: AppEnv.Dialogs | undefined,
  setDialogs: AppEnv.SetState<AppEnv.Dialogs | undefined>
) => {
  const markBlacklisted = (dialogs: AppEnv.Dialogs) => {
    const mapDialogs = (dialog: AppEnv.Dialog) => ({
      ...dialog,
      isBlacklisted: getIsDialogBlacklisted(user, dialog)
    });

    const mapInstances = ([key, dialogList]: [string, AppEnv.Dialog[]]) => [
      key,
      dialogList.map(mapDialogs)
    ];

    const newDialogs = Object.fromEntries(
      Object.entries(dialogs).map(mapInstances)
    );

    return newDialogs;
  };

  if (!('blacklist' in user)) return markBlacklisted;

  useEffect(() => {
    if (!dialogs) return;

    const res = markBlacklisted(dialogs);
    setDialogs(res);
  }, [user.blacklist]);

  return markBlacklisted;
};

export default useMarkBlacklisted;
