import {Stage} from '../../../tariff-list-env';
import {useTariffListContext} from '../../../tariff-list-context';

const useNewStage = (): Stage => {
  const {selectedTariff} = useTariffListContext();

  if (selectedTariff?.packet_name == 'Enterprise') return 'location';
  return 'payment-gateway';
};

export default useNewStage;
