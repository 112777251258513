import * as AppEnv from 'app-env';

const getDialogList = (dialogs: AppEnv.Dialogs, instance: AppEnv.Instance) => {
  const dialogList = dialogs[instance.id];
  if (!dialogList) return [];

  return dialogList.filter(dialog => !dialog.isBlacklisted);
};

export default getDialogList;
