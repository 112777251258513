import React from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import ReactDOM from 'react-dom/client';

import 'styles/main.sass';
import 'utils/i18n';
import App from 'App';
import Fallback from 'app/fallback/fallback';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <ErrorBoundary fallbackRender={Fallback}>
    <App />
  </ErrorBoundary>
);
