import React from 'react';

interface PageMain {
  children: React.ReactNode;
  flexGrow?: 0;
}

const PageMain = ({children, flexGrow}: PageMain) => (
  <div className="page__main" style={{flexGrow}}>
    {children}
  </div>
);

export default PageMain;
