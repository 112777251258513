import React from 'react';
import {Formik, Form} from 'formik';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {isolateNumbers} from 'whatcrm-core';
import * as yup from 'yup';
import update from 'immutability-helper';

import {
  Button,
  Popup,
  PopupFooter,
  PopupWrapper,
  SaveButton,
  Title,
  Wrapper
} from 'components';
import {getDialogId} from 'common/actions';
import {useAppContext} from 'app-context';
import {useDispatchContext} from '../../dispatch-context';
import {useGetDialog} from 'common/hooks';
import {useMessengerContext} from '../../../messenger-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import InstanceId from './instance-id/instance-id';
import Search from './search/search';

interface Values {
  instanceId: number | undefined;
  phone: string;
  type: 'phone' | 'username';
  username: string;
}

interface WriteFirst {
  isActive: boolean;
  setIsActive: AppEnv.SetState<boolean>;
}

const WriteFirst = ({isActive, setIsActive}: WriteFirst) => {
  const {isIframe, setNotification} = useAppContext();
  const {activeInstances} = useWorkspaceContext();
  const {setDialogs} = useMessengerContext();
  const {dialogList} = useDispatchContext();

  const {t} = useTranslation();
  const getDialog = useGetDialog();
  const navigate = useNavigate();

  const getIsDialogExist = (instance: AppEnv.Instance, id: number | string) => {
    const dialog = dialogList.find(dialog => getDialogId(dialog) == id);
    if (!dialog) return false;

    openDialog(`/messenger/${instance.id}/${getDialogId(dialog)}`);
    return true;
  };

  const openDialog = (path: string) => {
    setIsActive(false);
    navigate(path, {replace: isIframe});
  };

  const pushDialog = (instance: AppEnv.Instance, dialog: AppEnv.Dialog) => {
    setDialogs(prevValue =>
      update(prevValue, {[instance.id]: {$push: [dialog]}})
    );

    const dialogId = getDialogId(dialog);
    openDialog(`/messenger/${instance.id}/${dialogId}`);
  };

  const throwError = () => {
    setNotification({title: t`Could not find the user`});
    return true;
  };

  const findTelegram = async (
    instance: AppEnv.Instance,
    phone: string,
    username: string
  ) => {
    const dialog = await getDialog(instance, {phone, username});
    if (!dialog) return throwError();

    const dialogId = getDialogId(dialog);
    if (!dialogId) return true;

    const isDialogExist = getIsDialogExist(instance, dialogId);
    if (isDialogExist) return true;

    pushDialog(instance, dialog);
  };

  const findWhatsApp = async (instance: AppEnv.Instance, phone: string) => {
    const isDialogExist = getIsDialogExist(instance, phone);
    if (isDialogExist) return true;

    const dialog = await getDialog(instance, {phone});
    if (!dialog) return throwError();

    pushDialog(instance, dialog);
    return true;
  };

  const handleSubmit = async (values: Values) => {
    const instance = activeInstances.find(item => item.id == values.instanceId);
    if (!instance) return true;

    const phone = isolateNumbers(values.phone);
    const username = values.username.replaceAll('@', '');

    if (instance.version == 'telegram')
      return findTelegram(instance, phone, username);
    else if (instance.version == 'whatcrm')
      return findWhatsApp(instance, phone);

    return true;
  };

  const initialValues: Values = {
    instanceId: undefined,
    phone: '',
    type: 'phone',
    username: ''
  };

  const validationSchema = yup.object().shape({
    instanceId: yup.number().required(),
    phone: yup.string().when('type', {
      is: 'phone',
      then: () => yup.string().trim().required().min(6)
    }),
    type: yup.string().trim().required(),
    username: yup.string().when('type', {
      is: 'username',
      then: () => yup.string().trim().required()
    })
  });

  return (
    <Popup width={408} isActive={isActive} onClick={() => setIsActive(false)}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        <Form>
          <PopupWrapper>
            <Title.H3 style={{marginBottom: 16}}>{t`New contact`}</Title.H3>

            <Wrapper direction="column" gap={16}>
              <InstanceId />
              <Search />
            </Wrapper>
          </PopupWrapper>

          <PopupFooter>
            <Wrapper gap={12}>
              <SaveButton>{t`Search`}</SaveButton>

              <Button color="fade" onClick={() => setIsActive(false)}>
                {t`Close`}
              </Button>
            </Wrapper>
          </PopupFooter>
        </Form>
      </Formik>
    </Popup>
  );
};

export default WriteFirst;
