import React from 'react';
import {useTranslation} from 'react-i18next';

import {Icons} from 'components';
import * as AppEnv from 'app-env';

const useIcon = (crm: AppEnv.Crm) => {
  const {i18n} = useTranslation();

  if (['AMO', 'AVITO', 'TELEGRAM'].includes(crm)) {
    if (i18n.resolvedLanguage == 'ru') return <Icons.AmoCRM size={20} />;
    return <Icons.Kommo size={20} />;
  } else if (['B24', 'TELPHGRAM'].includes(crm))
    return <Icons.Bitrix24 size={20} />;
  else if (['LK'].includes(crm)) return <Icons.Whatcrm size={20} />;
  else if (['MONDAY', 'TGMONDAY'].includes(crm))
    return <Icons.Monday size={20} />;
  else if (['PIPEDRIVE'].includes(crm)) return <Icons.Pipedrive size={20} />;
};

export default useIcon;
