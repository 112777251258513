import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Input} from 'components';

const DomainPartner = () => {
  const [field, meta, helpers] = useField<string>('domain_partner');
  const {t} = useTranslation();

  const isError = !!meta.error && meta.touched;

  return (
    <Input
      {...field}
      autoFocus
      inputMode="url"
      isValid={!isError}
      onChange={helpers.setValue}
      placeholder={t`Partner domain`}
    />
  );
};

export default DomainPartner;
