import {formatPhoneNumber} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {processTelegramDialog} from 'common/actions';
import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

interface GetDialogParams {
  id?: string;
  phone?: string;
  username?: string;
}

const useGetDialog = () => {
  const {setNotification} = useAppContext();

  const {
    fetchCreatePrivateChat,
    fetchDialog,
    fetchSearchPublicChat,
    fetchSearchUserByPhoneNumber
  } = useRequest();

  const {t} = useTranslation();

  const findTelegramByPhone = async (
    instance: AppEnv.Instance,
    phone: string
  ) => {
    const user = await fetchSearchUserByPhoneNumber(instance.chat_key, phone);
    if (!user) return null;

    const dialog = await fetchCreatePrivateChat(instance.chat_key, user.id);

    if (dialog) return processTelegramDialog(dialog);
    else if (!user.username) return null;

    const customDialog: AppEnv.TelegramDialogShape = {
      id: user.id,
      isMarkedAsUnread: false,
      lastReadOutboxMessageId: 0,
      notificationSettings: {muteFor: 0, soundId: '-1'},
      profileImage: '',
      title: [user.firstName, user.lastName].join(' '),
      type: {isChannel: false},
      unreadCount: 0,
      username: user.username
    };

    return processTelegramDialog(customDialog);
  };

  const findTelegramByUsername = async (
    instance: AppEnv.Instance,
    username: string
  ) => {
    const dialog = await fetchSearchPublicChat(instance.chat_key, username);
    if (!dialog) return null;

    return processTelegramDialog(dialog);
  };

  const pushWhatsAppError = (phoneNumber?: string | null) =>
    setNotification({
      title: t`Contact not found`,
      text: phoneNumber
        ? t(
            'It seems the contact with the phone number {{phoneNumber}} is not registered on WhatsApp.',
            {phoneNumber: formatPhoneNumber(phoneNumber)}
          )
        : t`It seems the contact is not registered on WhatsApp.`
    });

  const findWhatsApp = async (instance: AppEnv.Instance, id: string) => {
    const res = await fetchDialog(instance.chat_key, id);
    const phoneNumber = id.endsWith('@c.us') ? id.split('@')[0] : null;

    if (!res) pushWhatsAppError(phoneNumber);
    return res;
  };

  const getDialog = async (
    instance: AppEnv.Instance,
    {id, phone, username}: GetDialogParams
  ) => {
    if (instance.version == 'telegram') {
      if (id && !id.includes('@')) return fetchDialog(instance.chat_key, id);
      else if (phone) return findTelegramByPhone(instance, phone);
      else if (username) return findTelegramByUsername(instance, username);
    } else if (instance.version == 'whatcrm') {
      if (id && id.includes('@')) return findWhatsApp(instance, id);
      else if (phone) return findWhatsApp(instance, `${phone}@c.us`);
    }

    return null;
  };

  return getDialog;
};

export default useGetDialog;
