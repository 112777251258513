import {useTariffsContext} from '../../../tariffs-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

const useSumma = (tariff: AppEnv.Tariff | undefined) => {
  const {user} = useWorkspaceContext();
  const {isPartner} = useTariffsContext();

  if (!tariff) return undefined;
  else if (isPartner) return tariff.summa;

  const {integration} = user;
  if (integration.is_review) return tariff.summa_review;

  return tariff.summa;
};

export default useSumma;
