import React from 'react';
import {useTranslation} from 'react-i18next';

import {Advice, Link, Text} from 'components';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

const Guide = () => {
  const {user} = useWorkspaceContext();
  const {i18n, t} = useTranslation();

  const getHref = () => {
    const {integration} = user;

    if (i18n.resolvedLanguage == 'ru')
      return 'https://whatcrm.gitbook.io/whatcrm-docs/produkty/whatcrm-web/shablony-dlya-whatcrm-web';
    else if (integration.type == 'PIPEDRIVE')
      return 'https://whatcrm.gitbook.io/whatcrm-docs/v/english/integrations-products/pipedrive/template-creation-instruction';

    return 'https://whatcrm.gitbook.io/whatcrm-docs/v/english/integrations-products/monday.com/telegram/template-creation-instruction';
  };

  const href = getHref();

  return (
    <Advice id="template-guide" style={{marginBottom: 16}}>
      <Text size={16}>
        <Link href={href} isTargetBlank>{t`Template guide`}</Link>
      </Text>
    </Advice>
  );
};

export default Guide;
