import React, {useContext} from 'react';
import * as AppEnv from 'app-env';
import {Integration} from './instances-env';

interface InstancesContext {
  integration: Integration;
  isPartner: boolean;
  onChange: (instance: AppEnv.Instance) => void;
  onDelete: (id: number) => void;
}

const InstancesContext = React.createContext<InstancesContext>(
  {} as InstancesContext
);

export const useInstancesContext = () => useContext(InstancesContext);
export default InstancesContext;
