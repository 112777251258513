import {FolderOption} from '../../../../folders-env';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useGetFolderDialogList} from 'common/hooks';

const useFolderDialogList = (folderOption: FolderOption) => {
  const {dialogList} = useDispatchContext();
  const getFolderDialogList = useGetFolderDialogList();

  const folderDialogList = getFolderDialogList(dialogList, folderOption.value);
  return folderDialogList;
};

export default useFolderDialogList;
