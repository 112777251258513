import React, {forwardRef} from 'react';
import cn from 'classnames';

import {Icons, Text, Tooltip} from 'components';

export interface ButtonProps {
  children: React.ReactNode;
  className?: string;
  color?: 'dashed' | 'fade' | 'green' | 'transparent' | 'white';
  isDisabled?: boolean;
  isMaxWidth?: boolean;
  isSmall?: boolean;
  isSubmit?: boolean;
  onClick?: () => void;
  state?: 'active' | 'disabled' | 'submitting';
  style?: React.CSSProperties;
  tooltip?: string;
  width?: '100%';
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      color = 'green',
      isDisabled,
      isMaxWidth,
      isSmall,
      isSubmit,
      onClick,
      state,
      style,
      tooltip,
      width
    },
    ref
  ) => {
    const isHtmlDisabled =
      isDisabled || (color == 'transparent' && state == 'submitting');

    const isLoading = color != 'transparent' && state == 'submitting';

    return (
      <button
        className={cn('btn', {
          'btn_max-width': isMaxWidth,
          'btn_type-icon': typeof children == 'object',
          [`${className}`]: className,
          [`btn_color-${color}`]: color,
          [`btn_state-${state}`]: state,
          btn_small: isSmall
        })}
        ref={ref}
        style={{...style, width}}
        type={isSubmit ? 'submit' : 'button'}
        disabled={isHtmlDisabled}
        onClick={() => (onClick ? onClick() : undefined)}
      >
        {isLoading ? <Icons.CircleNotch size={24} /> : children}

        {tooltip && (
          <Tooltip>
            <Text color="white">{tooltip}</Text>
          </Tooltip>
        )}
      </button>
    );
  }
);

Button.displayName = 'Button';

export {default as SaveButton} from './save-button/save-button';
export default Button;
