import React, {useState} from 'react';
import AccordionContext from './accordion-context';

interface Accordion {
  children: React.ReactNode;
  onDelete?: () => void;
}

const Accordion = ({children, onDelete}: Accordion) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <AccordionContext.Provider value={{isActive, onDelete, setIsActive}}>
      <div className="accordion">{children}</div>
    </AccordionContext.Provider>
  );
};

export {default as AccordionDetails} from './accordion-details/accordion-details';
export {default as AccordionSummary} from './accordion-summary/accordion-summary';

export default Accordion;
