import {useLocation} from 'react-router-dom';

const useInitialIsSidebarActive = () => {
  const location = useLocation();

  if (window.screen.width <= 768) return false;
  else if (
    !location.pathname.startsWith('/billing') &&
    !location.pathname.startsWith('/blocked') &&
    !location.pathname.startsWith('/connections') &&
    !location.pathname.startsWith('/partner-program') &&
    !location.pathname.startsWith('/settings') &&
    !location.pathname.startsWith('/templates') &&
    !location.pathname.startsWith('/widget')
  )
    return false;

  return true;
};

export default useInitialIsSidebarActive;
