import React, {useEffect, useState} from 'react';

import {Card} from 'components';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import Connector from './connector/connector';
import InstanceBody from './instance-body/instance-body';
import InstanceContext from './instance-context';
import InstanceHeader from './instance-header/instance-header';
import InstanceMenu from './instance-menu/instance-menu';

interface Instance {
  instance: AppEnv.Instance;
}

const Instance = ({instance}: Instance) => {
  const {connectorId} = useWorkspaceContext();

  const [isConfirmActive, setIsConfirmActive] = useState(false);
  const [isConnectorActive, setIsConnectorActive] = useState(false);
  const [isEditActive, setIsEditActive] = useState(false);

  useEffect(() => {
    if (connectorId != instance.id) return;
    setIsConnectorActive(true);
  }, [connectorId]);

  const isActive = isConfirmActive || isConnectorActive || isEditActive;

  return (
    <InstanceContext.Provider value={{instance}}>
      <Card isActive={isActive}>
        <InstanceHeader />
        <InstanceBody />

        <InstanceMenu
          isConfirmActive={isConfirmActive}
          isEditActive={isEditActive}
          setIsConfirmActive={setIsConfirmActive}
          setIsEditActive={setIsEditActive}
        />

        {instance.id == connectorId && (
          <Connector
            isConnectorActive={isConnectorActive}
            setIsConnectorActive={setIsConnectorActive}
          />
        )}
      </Card>
    </InstanceContext.Provider>
  );
};

export default Instance;
