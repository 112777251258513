import React from 'react';
import moment from 'moment';

import {TableData, TableRow} from 'components';
import {useTariffPeriod} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

interface Subscription {
  subscription: AppEnv.Subscription;
}

const Subscription = ({subscription}: Subscription) => {
  const {user} = useWorkspaceContext();
  const period = useTariffPeriod(subscription.tariff_quantity);
  const {integration} = user;

  return (
    <TableRow>
      <TableData>
        {moment(subscription.date_add).utcOffset(3).format('LLL')}
      </TableData>

      <TableData>{period}</TableData>
      <TableData>{subscription.tariff_plane || 'Start'}</TableData>

      <TableData>
        {subscription.title == 'cloudpayments'
          ? 'CloudPayments'
          : subscription.title == 'stripe'
            ? 'Stripe'
            : subscription.title}
      </TableData>

      {integration.is_partner > 0 && (
        <TableData>{subscription.domain}</TableData>
      )}
    </TableRow>
  );
};

export default Subscription;
