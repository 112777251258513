import React from 'react';
import {formatPhoneNumber, useCopyToClipboard} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {Item} from '../../components';
import {useAppContext} from 'app-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {getDialogName} from 'common/actions';
import * as AppEnv from 'app-env';

interface Participant {
  participant: AppEnv.WhatsAppParticipant;
}

const Participant = ({participant}: Participant) => {
  const {setNotification} = useAppContext();
  const {dialogList, instance} = useDispatchContext();

  const {t} = useTranslation();
  const copyToClipboard = useCopyToClipboard();

  const handleClick = async () => {
    const res = await copyToClipboard(participant.id.user);
    setNotification({title: res.message});
  };

  const dialog = dialogList.find(dialog => {
    const dialogId = dialog.id;
    const participantId = participant.id;

    if (typeof dialogId != 'object') return false;
    return dialogId._serialized == participantId._serialized;
  });

  const label: string[] = [];
  const name = dialog ? getDialogName(instance, dialog) || '' : '';
  const isName = name && !name?.startsWith('+');
  const phoneNumber = formatPhoneNumber(participant.id.user);

  if (isName) label.push(phoneNumber);

  if (participant.isSuperAdmin) label.push(t`super admin`);
  else if (participant.isAdmin) label.push(t`admin`);

  return (
    <Item
      key={participant.id._serialized}
      label={label.join(' • ')}
      type="copy"
      value={isName ? name : phoneNumber}
      onClick={handleClick}
    />
  );
};

export default Participant;
