import React from 'react';
import {useTranslation} from 'react-i18next';
import {Route, Routes} from 'react-router-dom';

import {Body, Nav, Page, PageBody, PageMain} from 'components';

import Payments from './payments/payments';
import Subscriptions from './subscriptions/subscriptions';

const Billing = () => {
  const {t} = useTranslation();

  const options = [
    {label: t`Payment history`, value: '/billing'},
    {label: t`Subscriptions`, value: '/billing/subscriptions'}
  ];

  return (
    <Body>
      <Page>
        <Nav options={options} value={location.pathname} />

        <PageBody>
          <PageMain>
            <Routes>
              <Route path="/" element={<Payments />} />
              <Route path="/subscriptions" element={<Subscriptions />} />
            </Routes>
          </PageMain>
        </PageBody>
      </Page>
    </Body>
  );
};

export default Billing;
