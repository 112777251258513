import React, {useState} from 'react';
import {Controlled} from 'react-medium-image-zoom';
import {useField} from 'formik';
import upd from 'immutability-helper';

import {Attach as IAttach} from '../../template-edit-env';
import {Anchor, Button, Icons, Text, Wrapper} from 'components';
import {downloadFile, getBlob} from 'common/actions';

interface Attach {
  attach: IAttach;
  index: number;
}

const Attach = ({attach, index}: Attach) => {
  const [isLoad, setIsLoad] = useState(false);
  const [isZoomed, setIsZoomed] = useState(false);

  const [field, , helpers] = useField<IAttach[]>('attachs');

  const handleDelete = () => {
    const newValue = upd(field.value, {[index]: {isDelete: {$set: true}}});
    helpers.setValue(newValue);
  };

  const handleDownload = async () => {
    const {templateFile} = attach;
    if (!templateFile) return;

    setIsLoad(true);

    const blob = await getBlob(templateFile.link);
    const href = blob || templateFile.link;

    downloadFile(href, templateFile.title, !blob);
    setIsLoad(false);
  };

  const {file, templateFile} = attach;
  const name = templateFile?.title || file?.name;

  return (
    <>
      {!attach.isDelete && (
        <Wrapper
          alignItems="center"
          gap={4}
          justifyContent="space-between"
          noWrap
        >
          {templateFile?.type?.startsWith('image') ? (
            <div>
              <Controlled isZoomed={isZoomed} onZoomChange={setIsZoomed}>
                <img
                  src={templateFile?.link}
                  style={{
                    position: 'absolute',
                    ...(isZoomed ? {} : {widht: 0, height: 0})
                  }}
                />
              </Controlled>

              <Anchor
                direction="rtl"
                onClick={() => setIsZoomed(true)}
                size={14}
              >
                <span>{name}</span>
              </Anchor>
            </div>
          ) : (
            <Text direction="rtl" isNoWrap>
              {name}
            </Text>
          )}

          <Wrapper noWrap>
            {templateFile?.link && (
              <Button
                color="transparent"
                isDisabled={isLoad}
                onClick={handleDownload}
              >
                {isLoad ? (
                  <Icons.CircleNotch size={20} />
                ) : (
                  <Icons.ArrowDown size={20} />
                )}
              </Button>
            )}

            <Button color="transparent" onClick={handleDelete}>
              <Icons.TrashSimple size={20} />
            </Button>
          </Wrapper>
        </Wrapper>
      )}
    </>
  );
};

export default Attach;
