import {useEffect, useState} from 'react';

import {useAppContext} from 'app-context';
import * as AppEnv from 'app-env';

import useMarkBlacklisted from './use-mark-blacklisted/use-mark-blacklisted';
import useCreateDialogs from './use-create-dialogs/use-create-dialogs';
import useGetDialogs from './use-get-dialogs/use-get-dialogs';

type UseDialogs = [
  AppEnv.Dialogs | undefined,
  AppEnv.SetState<AppEnv.Dialogs | undefined>
];

const useDialogs = (
  user: AppEnv.User,
  activeInstances: AppEnv.Instance[]
): UseDialogs => {
  const {isIframe} = useAppContext();
  const [dialogs, setDialogs] = useState<AppEnv.Dialogs>();

  const createDialogs = useCreateDialogs(activeInstances);
  const getDialogs = useGetDialogs(activeInstances);
  const markBlacklisted = useMarkBlacklisted(user, dialogs, setDialogs);

  const getDialogList = async () => {
    let newDialogs = await (isIframe ? createDialogs() : getDialogs());
    newDialogs = markBlacklisted(newDialogs);

    setDialogs(newDialogs);
  };

  useEffect(() => {
    if (process.env.REACT_APP_TYPE == 'app') return setDialogs([]);
    getDialogList();
  }, []);

  return [dialogs, setDialogs];
};

export default useDialogs;
