import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Input} from 'components';

const Title = () => {
  const [field, meta, helpers] = useField<string>('title');
  const {t} = useTranslation();

  const isValid = !meta.error || !meta.touched;

  return (
    <Input
      {...field}
      autoFocus
      color="gray"
      isSmall
      isValid={isValid}
      onChange={helpers.setValue}
      placeholder={t('Name', {context: 'connection'})}
    />
  );
};

export default Title;
