import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import {Event} from '../use-events-ws-env';
import {getIsInstancePaid} from 'common/actions';
import {useAppContext} from 'app-context';
import {
  useGetAvailableDialogList,
  useGetInstanceLabel,
  useRequest
} from 'common/hooks';
import {useWebSocketProviderContext} from 'pages/workspace/web-socket-provider/web-socket-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

const useHandleConnected = () => {
  const {setNotification} = useAppContext();
  const {setConnectorId, setUser, user} = useWorkspaceContext();
  const {setDialogs} = useWebSocketProviderContext();

  const {fetchDialogs} = useRequest();
  const {t} = useTranslation();
  const getAvailableDialogList = useGetAvailableDialogList();
  const getInstanceLabel = useGetInstanceLabel();

  const handleConnected = async (instance: AppEnv.Instance, event: Event) => {
    const {info} = event;
    const {me} = info || {};
    const id = info?.id || me?._serialized;

    setConnectorId(undefined);

    setUser(prevValue =>
      update(prevValue, {
        instances: {
          [instance.id]: {
            condition: {$set: undefined},
            me: {$set: {id: id || 0}},
            name: {$set: info?.pushname || ''},
            phone: {$set: info?.phoneNumber || me?.user || ''},
            status: {$set: 'CONNECTED'}
          }
        }
      })
    );

    const isInstancePaid = getIsInstancePaid(user, instance);
    if (!isInstancePaid) return;

    const res = await fetchDialogs(instance.chat_key);
    if (!res) return;

    const availableDialogList = getAvailableDialogList(instance, res);

    setDialogs(prevValue =>
      update(prevValue, {[instance.id]: {$set: availableDialogList}})
    );

    const title = getInstanceLabel(instance);
    setNotification({title, text: t`The connection is ready to go.`});
  };

  return handleConnected;
};

export default useHandleConnected;
