import React, {useState} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import cn from 'classnames';

import {useAppContext} from 'app-context';
import * as AppEnv from 'app-env';

import {
  useActiveInstances,
  useDialogs,
  useFavicon,
  useIsSidebarActive,
  useMe,
  useUpdateMessageBody
} from './hooks';
import Billing from './pages/billing/billing';
import Blocked from './pages/blocked/blocked';
import Connections from './pages/сonnections/connections';
import Header from './header/header';
import Messenger from './pages/messenger/messenger';
import PartnerProgram from './pages/partner-program/partner-program';
import Settings from './pages/settings/settings';
import Sidebar from './sidebar/sidebar';
import Templates from './pages/templates/templates';
import WebSocketProvider from './web-socket-provider/web-socket-provider';
import Widget from './pages/widget/widget';
import WorkspaceContext from './workspace-context';

interface Workspace {
  setUser: AppEnv.SetState<AppEnv.User | null | undefined>;
  user: AppEnv.User;
}

const Workspace = ({setUser, user}: Workspace) => {
  const {isIframe} = useAppContext();

  const [connectorId, setConnectorId] = useState<number>();
  const [instanceReferer, setInstanceReferer] = useState<string>();

  const activeInstances = useActiveInstances(user);
  const [dialogs, setDialogs] = useDialogs(user, activeInstances);

  const [isSidebarActive, setIsSidebarActive] = useIsSidebarActive();

  const {updateMessageBody, updateQuotedMsgBody} = useUpdateMessageBody(
    dialogs,
    setDialogs
  );

  useFavicon(dialogs);
  useMe(activeInstances, setUser);

  const isWebSocket = !!dialogs && process.env.REACT_APP_TYPE == 'web';

  const to =
    process.env.REACT_APP_TYPE == 'app' ? '/connections' : '/messenger';

  return (
    <WorkspaceContext.Provider
      value={{
        activeInstances,
        connectorId,
        instanceReferer,
        setConnectorId,
        setInstanceReferer,
        setUser,
        updateMessageBody,
        updateQuotedMsgBody,
        user
      }}
    >
      <div className={cn('workspace', {workspace_sidebar: isSidebarActive})}>
        <Header
          isSidebarActive={isSidebarActive}
          setIsSidebarActive={setIsSidebarActive}
        />

        <Sidebar dialogs={dialogs} setIsSidebarActive={setIsSidebarActive} />

        <Routes>
          <Route path="/billing/*" element={<Billing />} />
          <Route path="/blocked" element={<Blocked />} />

          <Route
            path="/connections/*"
            element={<Connections setDialogs={setDialogs} />}
          />

          <Route
            path="/messenger/:instanceId?/:dialogId?"
            element={<Messenger dialogs={dialogs} setDialogs={setDialogs} />}
          />

          <Route path="/partner-program" element={<PartnerProgram />} />
          <Route path="/settings/*" element={<Settings />} />
          <Route path="/templates" element={<Templates />} />
          <Route path="/widget/*" element={<Widget />} />
          <Route path="/widget/*" element={<Widget />} />
          <Route path="*" element={<Navigate replace={isIframe} to={to} />} />
        </Routes>
      </div>

      {isWebSocket && (
        <WebSocketProvider dialogs={dialogs} setDialogs={setDialogs} />
      )}
    </WorkspaceContext.Provider>
  );
};

export default Workspace;
