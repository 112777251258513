import React from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';

import {getUrlParams} from 'common/actions';
import {Placeholder} from 'components';
import {useAppContext} from 'app-context';
import * as AppEnv from 'app-env';
import Auth from 'pages/auth/auth';
import Error403 from 'pages/error-403/error-403';
import Record from 'pages/record/record';
import Workspace from 'pages/workspace/workspace';

interface Router {
  setUser: AppEnv.SetState<AppEnv.User | null | undefined>;
  user: AppEnv.User | null;
}

const Router = ({setUser, user}: Router) => {
  const {isIframe} = useAppContext();

  const {crm} = getUrlParams();
  const isError403 = !!crm && crm != 'LK';

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/auth" element={<Auth setUser={setUser} user={user} />} />

        <Route path="/record" element={<Record />} />

        <Route
          path="*"
          element={
            user ? (
              <Workspace setUser={setUser} user={user} />
            ) : isError403 ? (
              <Error403 />
            ) : isIframe ? (
              <Placeholder isMaxHeight />
            ) : (
              <Navigate to="/auth" />
            )
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
