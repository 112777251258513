import React from 'react';

interface Page {
  children: React.ReactNode;
}

const Page = ({children}: Page) => (
  <div className="page">
    <div className="page__wrapper">{children}</div>
  </div>
);

export {default as PageBody} from './page-body/page-body';
export {default as PageFooter} from './page-footer/page-footer';
export {default as PageMain} from './page-main/page-main';

export default Page;
