import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {PageBody, PageMain, Placeholder, Preloader} from 'components';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import {Integration} from './tariffs-env';
import TariffList from './tariff-list/tariff-list';
import TariffsContext from './tariffs-context';

interface Tariffs {
  instances: AppEnv.Instances | undefined;
  integration: Integration;
  isPartner?: boolean;
  setTariffs: AppEnv.SetState<AppEnv.Tariffs | null | undefined>;
  tariffs: AppEnv.Tariffs | null | undefined;
}

const Tariffs = ({
  instances,
  integration,
  isPartner = false,
  setTariffs,
  tariffs
}: Tariffs) => {
  const {user} = useWorkspaceContext();

  const {i18n, t} = useTranslation();
  const {fetchTariffs} = useRequest();

  const getCurrency = () => {
    const {integration} = user;

    if (i18n.resolvedLanguage == 'ru' && integration.country != 'UA')
      return 'RUB';

    return 'USD';
  };

  const getTariffList = async () => {
    const currency = getCurrency();

    const tariffs = await fetchTariffs(
      integration.type,
      integration.domain,
      currency,
      isPartner ? 1 : undefined
    );

    setTariffs(tariffs);
  };

  useEffect(() => {
    getTariffList();
  }, []);

  return (
    <TariffsContext.Provider value={{instances, integration, isPartner}}>
      {typeof tariffs == 'object' ? (
        tariffs ? (
          <TariffList tariffs={tariffs} />
        ) : (
          <PageBody>
            <PageMain>
              <Placeholder title={t`No rate plans`} isMaxHeight />
            </PageMain>
          </PageBody>
        )
      ) : (
        <PageBody>
          <PageMain>
            <Preloader isMaxHeight />
          </PageMain>
        </PageBody>
      )}
    </TariffsContext.Provider>
  );
};

export default Tariffs;
