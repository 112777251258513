import React from 'react';

interface TableData {
  children?: React.ReactNode;
}

const TableData = ({children}: TableData) => (
  <td className="table__data">{children}</td>
);

export default TableData;
