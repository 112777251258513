import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {
  Button,
  Chips,
  ChipsOption,
  Popup,
  PopupFooter,
  PopupWrapper,
  Text,
  Title,
  Wrapper
} from 'components';
import {PaymentGateway as IPaymentGateway, Stage} from '../tariff-list-env';
import {useTariffsContext} from '../../tariffs-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

interface PaymentGateway {
  paymentGateway: IPaymentGateway | undefined;
  setPaymentGateway: AppEnv.SetState<IPaymentGateway | undefined>;
  setStage: AppEnv.SetState<Stage | undefined>;
  stage: Stage | undefined;
}

const PaymentGateway = ({
  paymentGateway,
  setPaymentGateway,
  setStage,
  stage
}: PaymentGateway) => {
  const {user} = useWorkspaceContext();
  const {isPartner} = useTariffsContext();

  const {i18n, t} = useTranslation();
  const {integration} = user;

  const isCloudPaymentsShown =
    i18n.resolvedLanguage == 'ru' && integration.country != 'UA';

  const isTelphinAdviceShown =
    i18n.resolvedLanguage == 'ru' && !integration.telphin_code;

  const isTelphinShown =
    (i18n.resolvedLanguage == 'ru' || integration.telphin_code) && !isPartner;

  const isTelphinWarningShown =
    integration.telphin_code && paymentGateway && paymentGateway != 'telphin';

  const options: ChipsOption<IPaymentGateway>[] = [
    {
      icon: <img src="/static/img/payment-gateways/stripe.svg" />,
      label: 'Stripe',
      value: 'stripe'
    }
  ];

  if (isCloudPaymentsShown)
    options.push({
      icon: <img src="/static/img/payment-gateways/cloudpayments.svg" />,
      label: 'CloudPayments',
      value: 'cloud'
    });

  if (isTelphinShown)
    options.push({
      icon: (
        <img
          src={`/static/img/payment-gateways/telphin_${i18n.resolvedLanguage == 'ru' ? 'ru' : 'en'}.svg`}
        />
      ),
      isDisabled: !integration.telphin_code,
      label: t`Telphin`,
      value: 'telphin'
    });

  useEffect(() => {
    if (options.length > 1 || stage != 'payment-gateway') return;

    setPaymentGateway('stripe');
    setStage('license-agreement');
  }, [stage]);

  return (
    <Popup
      width={408}
      isActive={stage == 'payment-gateway'}
      onClick={() => setStage(undefined)}
    >
      <PopupWrapper>
        <Title.H3 style={{marginBottom: 16}}>{t`Payment method`}</Title.H3>

        <Wrapper direction="column" gap={16}>
          <Chips
            options={options}
            value={paymentGateway}
            onChange={setPaymentGateway}
          />

          {!isPartner &&
            (isTelphinAdviceShown ? (
              <Text>{`Чтобы оплатить с баланса личного кабинета «Телфин» необходимо заполнить реквизиты в настройках виджета.`}</Text>
            ) : (
              isTelphinWarningShown && (
                <div>
                  <Text style={{marginBottom: 8}}>
                    {`Платеж картой будет считаться оплатой от физического лица и не отобразится в бухгалтерском акте сверки.`}
                  </Text>

                  <Text>
                    {`Чтобы платеж попал в акт, необходимо выбрать способ оплаты «`}
                    <Text fontWeight={600} isSpan>{`Телфин`}</Text>
                    {`».`}
                  </Text>
                </div>
              )
            ))}
        </Wrapper>
      </PopupWrapper>

      <PopupFooter>
        <Wrapper gap={12}>
          <Button
            isDisabled={!paymentGateway}
            onClick={() => setStage('license-agreement')}
          >
            {t`Continue`}
          </Button>

          <Button color="fade" onClick={() => setStage(undefined)}>
            {t`Close`}
          </Button>
        </Wrapper>
      </PopupFooter>
    </Popup>
  );
};

export default PaymentGateway;
