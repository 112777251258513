import React from 'react';
import NavItem, {NavItemParams} from './nav-item/nav-item';

interface NavProps<T> {
  onChange?: (value: T) => void;
  options: NavItemParams<T>[];
  style?: React.CSSProperties;
  value: T;
}

const Nav = <T extends string>({
  onChange,
  options,
  style,
  value
}: NavProps<T>) => (
  <div className="nav" style={style}>
    {options.map((item, i) => (
      <NavItem
        isActive={item.value == value}
        key={i}
        navItem={item}
        onChange={onChange}
      />
    ))}
  </div>
);

export default Nav;
