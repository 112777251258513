import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface AccordionContext {
  isActive: boolean;
  onDelete?: () => void;
  setIsActive: AppEnv.SetState<boolean>;
}

const AccordionContext = React.createContext<AccordionContext>(
  {} as AccordionContext
);

export const useAccordionContext = () => useContext(AccordionContext);
export default AccordionContext;
