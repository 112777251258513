import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface MessengerContext {
  setDialogs: AppEnv.SetState<AppEnv.Dialogs | undefined>;
}

const MessengerContext = React.createContext<MessengerContext>(
  {} as MessengerContext
);

export const useMessengerContext = () => useContext(MessengerContext);
export default MessengerContext;
