import Fuse from 'fuse.js';

import {useGetFolderDialogList} from 'common/hooks';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {getIsNewMessageAvailable, sortDialogs} from 'common/actions';

const useFusedDialogList = (search: string) => {
  const {dialogList, instance} = useDispatchContext();
  const getFolderDialogList = useGetFolderDialogList();

  const folderDialogList = getFolderDialogList(dialogList, null);

  const fuse = new Fuse(folderDialogList, {
    keys: ['id.user', 'id', 'name'],
    threshold: 0.25
  });

  let fusedDialogList = folderDialogList.filter(item =>
    getIsNewMessageAvailable(instance, item)
  );

  fusedDialogList = search
    ? fuse.search(search).map(item => item.item)
    : fusedDialogList;

  fusedDialogList.sort(sortDialogs);

  return fusedDialogList;
};

export {useFusedDialogList};
