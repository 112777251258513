import React from 'react';
import cn from 'classnames';

import {Icons} from 'components';

interface Preloader {
  isMaxHeight?: boolean;
  size?: 24;
}

const Preloader = ({isMaxHeight, size}: Preloader) => (
  <div className={cn('preloader', {'preloader_max-height': isMaxHeight})}>
    <Icons.CircleNotch size={size} />
  </div>
);

export default Preloader;
