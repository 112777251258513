import _ from 'lodash';

import {useInstancesContext} from '../instances-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

interface ChatSpareData {
  label?: string;
  name?: string;
}

const useChatSpare = () => {
  const {integration, onChange} = useInstancesContext();

  const {postChatSpare, postChatUpdate} = useRequest();

  const chatSpare = async (
    version: AppEnv.InstanceVersion,
    data?: ChatSpareData
  ) => {
    const res = await postChatSpare(
      integration.type,
      integration.domain,
      version,
      data?.label
    );

    if (!res) return null;
    else if (data?.name)
      await postChatUpdate(res.chat_key, {name: data.name, phone: '9428276'});

    const chatInfo: AppEnv.ChatInfo = _.pick(res, [
      'date_pay',
      'date_subscription',
      'date_trial',
      'is_premium',
      'tariff_plane',
      'webhook'
    ]);

    const instance: AppEnv.Instance = {
      ...chatInfo,
      ...res,
      instanceId: res.chat_id,
      name: data?.name || res.name
    };

    onChange(instance);
    return instance;
  };

  return chatSpare;
};

export {useChatSpare};
