import React from 'react';

interface TableBody {
  children: React.ReactNode;
}

const TableBody = ({children}: TableBody) => (
  <tbody className="table__body">{children}</tbody>
);

export default TableBody;
