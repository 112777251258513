import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Button, CardMenu, Confirm, Icons, Wrapper} from 'components';
import {useAppContext} from 'app-context';
import {useGetInstanceLabel, useRequest} from 'common/hooks';
import {useInstanceContext} from '../instance-context';
import {useInstancesContext} from '../../../instances-context';
import * as AppEnv from 'app-env';

import Connect from './connect/connect';
import Editor from './editor/editor';

interface InstanceMenu {
  isConfirmActive: boolean;
  isEditActive: boolean;
  setIsConfirmActive: AppEnv.SetState<boolean>;
  setIsEditActive: AppEnv.SetState<boolean>;
}

const InstanceMenu = ({
  isConfirmActive,
  isEditActive,
  setIsConfirmActive,
  setIsEditActive
}: InstanceMenu) => {
  const {setNotification} = useAppContext();
  const {isPartner, onDelete} = useInstancesContext();
  const {instance} = useInstanceContext();

  const [isFetching, setIsFetching] = useState(false);

  const {fetchChatFree} = useRequest();
  const {t} = useTranslation();
  const getInstanceLabel = useGetInstanceLabel();

  const deleteInstance = async () => {
    const res = await fetchChatFree(instance.chat_key);
    if (!res?.success) return true;

    const title = getInstanceLabel(instance);

    setNotification({title, text: t`The connection has been deleted.`});
    onDelete(instance.id);

    return true;
  };

  const handleConfirm = (res: boolean) =>
    res ? deleteInstance() : setIsConfirmActive(false);

  return (
    <>
      <CardMenu>
        {isPartner ? (
          <Button
            color="transparent"
            isDisabled={isFetching}
            onClick={() => setIsConfirmActive(true)}
          >
            <Icons.TrashSimple size={20} />
            <span>{t`Delete`}</span>
          </Button>
        ) : (
          <>
            <Connect isFetching={isFetching} setIsFetching={setIsFetching} />

            <Wrapper noWrap>
              <Button
                color="transparent"
                isDisabled={isFetching}
                onClick={() => setIsEditActive(true)}
              >
                <Icons.PencilSimple size={20} />
              </Button>

              <Button
                color="transparent"
                isDisabled={isFetching}
                onClick={() => setIsConfirmActive(true)}
              >
                <Icons.TrashSimple size={20} />
              </Button>
            </Wrapper>
          </>
        )}
      </CardMenu>

      <Confirm
        title={t`Are you sure you want to delete this connection?`}
        isActive={isConfirmActive}
        onClick={handleConfirm}
      />

      <Editor isEditActive={isEditActive} setIsEditActive={setIsEditActive} />
    </>
  );
};

export default InstanceMenu;
