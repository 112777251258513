import React from 'react';
import {Link} from 'react-router-dom';
import cn from 'classnames';

import {useAppContext} from 'app-context';

export interface NavItemParams<T> {
  label: string;
  value: T;
}

interface NavItem<T> {
  isActive: boolean;
  navItem: NavItemParams<T>;
  onChange: ((value: T) => void) | undefined;
}

const NavItem = <T extends string>({
  isActive,
  navItem,
  onChange
}: NavItem<T>) => {
  const {isIframe} = useAppContext();
  const className = cn('nav__item', {nav__item_active: isActive});

  return (
    <>
      {onChange ? (
        <span className={className} onClick={() => onChange(navItem.value)}>
          {navItem.label}
        </span>
      ) : (
        <Link className={className} replace={isIframe} to={navItem.value}>
          {navItem.label}
        </Link>
      )}
    </>
  );
};

export default NavItem;
