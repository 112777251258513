import React, {useState} from 'react';
import {getFromLocalStorage, putInLocalStorage} from 'whatcrm-core';
import cn from 'classnames';

import {Button, Icons} from 'components';

interface Advice {
  children: React.ReactNode;
  id?: string;
  style?: React.CSSProperties;
}

const Advice = ({children, id = '', style}: Advice) => {
  const [isShown, setIsShown] = useState(!getFromLocalStorage(id));

  const handleClick = () => {
    setIsShown(false);
    putInLocalStorage(id, '1');
  };

  return (
    <>
      {isShown && (
        <div className={cn('advice', {advice_alt: id})} style={style}>
          <div className="advice__content">{children}</div>

          {id && (
            <Button color="transparent" onClick={handleClick}>
              <Icons.Close color="blue" />
            </Button>
          )}
        </div>
      )}
    </>
  );
};

export default Advice;
