import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {
  Placeholder,
  Preloader,
  Select,
  Table,
  TableBody,
  TableData,
  TableHead
} from 'components';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import Payment from './payment/payment';

const Payments = () => {
  const {user} = useWorkspaceContext();

  const [domain, setDomain] = useState('');
  const [paymentList, setPaymentList] = useState<AppEnv.Payment[]>();

  const {fetchPayments} = useRequest();
  const {t} = useTranslation();

  useEffect(() => {
    document.title = `${t`Payment history`} - Whatcrm Web`;
  }, []);

  const getPaymentList = async () => {
    const {integration} = user;

    const res = await fetchPayments(integration.id);
    setPaymentList(res || []);
  };

  useEffect(() => {
    getPaymentList();
  }, []);

  const {integration} = user;

  const domains = paymentList?.reduce((acc: string[], payment) => {
    if (!acc.includes(payment.domain)) acc.push(payment.domain);
    return acc;
  }, []);

  const fusedPaymentList = domain
    ? paymentList?.filter(payment => payment.domain == domain)
    : paymentList;

  const isDeleted = paymentList?.some(payment => payment.is_deleted) || false;

  const options =
    domains?.map(domain => ({label: domain, value: domain})) || [];

  options.unshift({label: t`All domains`, value: ''});

  return (
    <>
      {fusedPaymentList ? (
        fusedPaymentList.length ? (
          <>
            <Select
              onChange={setDomain}
              options={options}
              placeholder={t`All domains`}
              style={{marginBottom: 16}}
              value={domain}
            />

            <Table>
              <TableHead>
                <TableData>
                  {t`Date`}
                  {` (UTC+3)`}
                </TableData>

                <TableData>{t`Amount`}</TableData>
                <TableData>{t`Period`}</TableData>
                <TableData>{t`Rate plan`}</TableData>

                {integration.is_partner > 0 && (
                  <TableData>{t`Domain`}</TableData>
                )}

                {isDeleted && <TableData />}
              </TableHead>

              <TableBody>
                {fusedPaymentList.map((payment, i) => (
                  <Payment isDeleted={isDeleted} key={i} payment={payment} />
                ))}
              </TableBody>
            </Table>
          </>
        ) : (
          <Placeholder isMaxHeight title={t`No payment history`} />
        )
      ) : (
        <Preloader isMaxHeight />
      )}
    </>
  );
};

export default Payments;
