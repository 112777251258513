import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {downloadFile, getBlob} from 'common/actions';
import {MessageDocument, UserMessage} from '../../components';
import {useAppContext} from 'app-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useRequest} from 'common/hooks';
import {useWhatsAppMessageContext} from '../whatsapp-message-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

const Document = () => {
  const {setNotification} = useAppContext();
  const {updateMessageBody} = useWorkspaceContext();
  const {instance} = useDispatchContext();
  const {message} = useWhatsAppMessageContext();

  const [blob, setBlob] = useState<string>();
  const [isFetching, setIsFetching] = useState(false);

  const {fetchDownloadMedia} = useRequest();
  const {t} = useTranslation();

  const throwError = () => {
    setNotification({title: t`Failed to download the file`});
    setIsFetching(false);
  };

  const getUrl = async () => {
    if (message.body) return message.body;
    else if (!message.mediaKey) return null;

    const media = await fetchDownloadMedia(
      instance.chat_key,
      message.id,
      message.mediaKey
    );

    if (!media || !('url' in media)) return null;

    updateMessageBody(message.id, media.url);
    return media.url;
  };

  const handleClick = async () => {
    const {filename} = message._data;

    if (blob) return downloadFile(blob, filename);
    setIsFetching(true);

    const url = await getUrl();
    if (!url) return throwError();

    const newBlob = await getBlob(url);
    const file = newBlob || url;

    downloadFile(file, filename, !newBlob);
    setBlob(file);
    setIsFetching(false);
  };

  const {_data} = message;
  const caption = _data.caption != _data.filename ? _data.caption : undefined;

  return (
    <UserMessage>
      <MessageDocument
        caption={caption}
        fileName={_data.filename}
        isLoading={isFetching}
        onClick={handleClick}
        size={_data.size}
      />
    </UserMessage>
  );
};

export default Document;
