import React from 'react';
import {useTranslation} from 'react-i18next';

import {Advice, Link, Text} from 'components';

const CloudPaymentsAdvice = () => {
  const {t} = useTranslation();

  return (
    <Advice>
      <Text size={16}>
        {t`You can cancel your subscription on the`}{' '}
        <Link href="https://my.cloudpayments.ru" isTargetBlank>
          {t`CloudPayments website`}
        </Link>
        {'. '}
        {t`Enter your card details and the subscription price.`}{' '}
        {t`Then find our product and click “Cancel”.`}
      </Text>
    </Advice>
  );
};

export default CloudPaymentsAdvice;
