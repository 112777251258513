import React from 'react';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

import PartnerAdd from './partner-add/partner-add';
import PartnerInfo from './partner-info/partner-info';

const Partner = () => {
  const {user} = useWorkspaceContext();
  const {partner = {}} = 'partner' in user ? user : {};

  return Object.keys(partner || {}).length ? <PartnerInfo /> : <PartnerAdd />;
};

export default Partner;
