import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Input} from 'components';

const Phone = () => {
  const [field, meta, helpers] = useField<string>('phone');
  const {t} = useTranslation();

  const isError = meta.error && meta.touched;

  return (
    <Input
      {...field}
      autoCapitalize="off"
      autoFocus
      color="gray"
      isSmall
      isValid={!isError}
      onChange={helpers.setValue}
      placeholder={t`Phone number or ID`}
    />
  );
};

export default Phone;
