import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface DispatchContext {
  dialogList: AppEnv.Dialog[];
  folder: AppEnv.Folder;
  instance: AppEnv.Instance;
  requestDialogProfileImage: (dialog: AppEnv.Dialog) => Promise<void>;
  setFolder: AppEnv.SetState<AppEnv.Folder>;
}

const DispatchContext = React.createContext<DispatchContext>(
  {} as DispatchContext
);

export const useDispatchContext = () => useContext(DispatchContext);
export default DispatchContext;
