import {useGetIsDialogLimited} from 'common/hooks';
import * as AppEnv from 'app-env';

const useGetAvailableDialogList = () => {
  const getIsDialogLimited = useGetIsDialogLimited();

  const getAvailableDialogList = (
    instance: AppEnv.Instance,
    dialogList: AppEnv.Dialog[]
  ) => dialogList.filter(item => !getIsDialogLimited(instance, item));

  return getAvailableDialogList;
};

export default useGetAvailableDialogList;
