import {useInstanceContext} from '../../instance-context';

export const useInstancePaymentStatus = () => {
  const {instance} = useInstanceContext();

  if (instance.is_premium) return 'premium';
  else if ((instance.date_subscription || 0) * 1000 > Date.now()) return 'paid';
  else if ((instance.date_trial || 0) * 1000 > Date.now()) return 'trial';

  return 'unpaid';
};
